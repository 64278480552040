<template>
    <v-container>
        <v-dialog
                v-model="dialog"
                max-width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex justify-end"
                >Anbieter Löschen
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Anbieter Löschen
                </v-card-title>

                <v-card-text>
                    Soll dieser Anbieter <strong>{{anbieter_model.name}}</strong> wirklich gelöscht werden?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="primary" text @click="dialog = false">Nein</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="removeAnbieter">Ja, löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-spacer class="mb-4"></v-spacer>
        <v-form>
            <v-text-field v-model="anbieter_model.name" label="Name" required></v-text-field>
            <v-textarea v-model="anbieter_model.address" label="Adresse"></v-textarea>
            <v-text-field v-model="anbieter_model.logo" label="Logo URL"></v-text-field>
            <v-spacer></v-spacer>
            <v-btn block @click="saveAnbieter" color="primary">Speichern</v-btn>
            <v-spacer class="my-2"></v-spacer>
            <v-btn block @click="saveAnbieterAndBack" color="secondary">Speichern und Zurück zur Übersicht</v-btn>
        </v-form>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "AnbieterEdit",
        computed: {
            ...mapGetters('oidcStore', [
                'oidcIsAuthenticated',
                'oidcAccessToken',
                'oidcError'
            ])
        },
        data() {
            return {
                anbieter_model: this.anbieter,
                dialog: null
            }
        },
        methods: {
            ...mapActions('oidcStore', [
                'authenticateOidcSilent', // Authenticates if signed in. No redirect is made if not signed in
            ]),
            async saveAnbieter() {
                if (!this.oidcIsAuthenticated) {
                    this.save_counter++
                    this.authenticateOidcSilent().then(
                        () => {
                            if (this.save_counter < 3) {
                                this.saveAnbieter()
                            }
                        }
                    )
                    return
                }
                const model = this.$store.$db().model('anbieter')
                await model.api().put(`/api/v1/anbieter/${this.anbieter_model.id}`, this.anbieter_model, {
                    headers: {'Authorization': `Bearer ${this.oidcAccessToken}`}
                })
            },
            saveAnbieterAndBack() {
                this.saveAnbieter().then(() => this.$router.push({path: '/anbieter/'}))
            },
            removeAnbieter() {
                const model = this.$store.$db().model('anbieter')
                model.api().delete(`/api/v1/anbieter/${this.anbieter_model.id}`, {
                    headers: {'Authorization': `Bearer ${this.oidcAccessToken}`}
                }).then(() => {
                    this.dialog = false
                    model.delete(this.anbieter_model.id)
                    this.$router.push({path: '/anbieter/'})
                })
            }

        },
        props: {
            anbieter: Object
        },
    }
</script>

<style scoped>

</style>
