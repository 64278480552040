<template>
    <AnbieterEdit :anbieter="anbieter"></AnbieterEdit>
</template>

<script>
    import AnbieterEdit from '@/components/AnbieterEdit';
    export default {
        name: "EditAnbieter",
        components: {AnbieterEdit},
        computed: {
            anbieter: {
                get() {
                    return this.$store.$db().model('anbieter').query().find(this.$route.params.id)
                }
            }
        }
    }
</script>

<style scoped>

</style>
